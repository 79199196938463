import React from "react";
import Img1 from "./../../images/Yealink_SIP-T46G.png";

const Purchasephones = () => {
  return (
    <div>
      <div className="max-w-7xl m-auto px-6 md:px-8 py-20">
        <h2 className="text-center text-[38px] font-semibold mb-4">
          Need to purchase phones?
        </h2>
        <p className="text-center text-[21px] font-light max-w-[865px] mx-auto md:mb-12 mb-8">
          You can choose phones during your online checkout OR when you set up
          your account after checkout, you'll also be able to easily purchase
          hardware. Here are some of our most popular phones:
        </p>
        <div className="grid md:grid-cols-3  grid-cols-1 gap-7">
          {phones.map(({ id, img, title }) => {
            return (
              <div className="flex flex-col justify-center items-center">
                <div>
                  <img src={img} alt="" className="w-72" />
                </div>
                <h5 className="font-semibold mt-7 border-black border-b-2">
                  {title}
                </h5>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Purchasephones;
const phones = [
  {
    id: 1,
    img: Img1,
    title: "Yealink® T48S",
    link: "",
  },
  {
    id: 2,
    img: Img1,
    title: "Yealink® T46S",
    link: "",
  },
  {
    id: 3,
    img: Img1,
    title: "Yealink® T42S",
    link: "",
  },
];
