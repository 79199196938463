import React from "react";
import Speak from "./../../components/Speak";
import EveryCompany from "./EveryCompany";
import Plans from "./Plans";
import Purchasephones from "./Purchasephones";

function Index({location}) {
  return (
    <div>
      <Plans />
      <EveryCompany />
      <Purchasephones />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
