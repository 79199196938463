import React from "react";
import Img1 from "./../../images/Random_Scene_2.svg";
import Img2 from "./../../images/Random_Scene_1.svg";

const EveryCompany = () => {
  return (
    <div className="max-w-7xl m-auto px-6 md:px-8 py-20">
      <div className="grid md:grid-cols-2 grid-cols-1 pb-20 lg:gap-12 gap-8 items-center">
        <div className="lg:order-1 md:order-1 order-last">
          <h2 className="text-[29px] mb-5 font-semibold">
            Every company needs awesome business phone service
          </h2>
          <p className="mb-[30px] text-[17px] font-normal">
            That's why all of our business phone plans include:
          </p>
          <ul className="columns-2 list-disc px-4 gap-6">
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">
                Phone service via your high-speed internet
              </p>
            </li>
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">
                The ability to keep your existing number
              </p>
            </li>
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">50+ business phone features—standard</p>
            </li>
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">
                24/7 US-based service and tech support
              </p>
            </li>
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">99.999% uptime reliability*</p>
            </li>
          </ul>
          <div className="pt-12">
            <a href="/" className="font-bold border-b-2 border-black mx-4">
              See all features
            </a>
          </div>
        </div>
        <div className="lg:order-last md:order-last order-1">
          <img src={Img2} alt="" className="rounded-xl z-50" />
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 mt-20 lg:gap-12 gap-8 items-center">
        <div>
          <img src={Img1} alt="" className="rounded-xl z-50" />
        </div>
        <div>
          <h2 className="text-[29px] mb-5 font-semibold">
            Add the bells and whistles you need most
          </h2>
          <p className="mb-[30px] text-[17px] font-normal">
            Our most popular add-on features include:
          </p>
          <ul className="columns-2 list-disc px-4 gap-8">
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">
                Toll-free numbers: an easy way for your customers to connect
                with you
              </p>
            </li>
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">
                Vonage Support PLUS powered by Soluto™: personalized, friendly
                remote support for IT and tech issues
              </p>
            </li>
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">
                Call recording: available for your entire office, or on-demand
              </p>
            </li>
            <li className="text-[#881fff] mb-[10px] text-sm">
              <p className="text-black">
                Visual voicemail: receive transcripts of your voicemails
                directly in your email inbox
              </p>
            </li>
          </ul>
          <div className="pt-12">
            <a href="/" className="font-bold border-b-2 border-black mx-4">
              See all features
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EveryCompany;
