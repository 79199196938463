import React, { useState } from "react";
import { Link } from "gatsby";
import { TiTick } from "react-icons/ti";
import Slider from "@mui/material/Slider";

const Plans = () => {
  const [priceVal, setPriceVal] = useState();
  const valuetext = (value) => {
    setPriceVal(value);
  };

  return (
    <div className="bg-[#eeeffc]">
      <div className="px-4 py-24 mx-auto max-w-7xl sm:px-6 lg:px-4">
        <h2 className="text-3xl font-semibold text-center text-gray-900 md:text-4xl sm:leading-none sm:tracking-tight ">
          How many employees does your company have?
        </h2>
        <p className="mt-6 text-lg text-center text-gray-500 md:text-xl">
          Main local company number included with online purchases.1
        </p>
        <div className="max-w-[500px] mx-auto mt-14">
          <Slider
            defaultValue={50}
            size="large"
            aria-label="Small steps"
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            step={1}
            min={1}
            max={1000}
            sx={{
              height:"10px",
            }}
          />
        </div>
        <div className="mt-24 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
          {priceInfo.map(
            ({
              name,
              title,
              desc,
              priceTill4,
              priceTill19,
              priceatill99,
              shortDesc,
              btn,
              link,
              feature,
              answer,
            }) => {
              return (
                <div className="relative flex flex-col p-8 bg-white shadow-sm rounded-3xl">
                  <div className="flex-1">
                    <h3 className="absolute top-0 left-0 right-0 py-2 text-sm font-semibold text-center text-white bg-black rounded-t-xl">
                      {name}
                    </h3>
                    <p className="mt-4 text-center text-[32px]">{title}</p>
                    <p className="text-[15px]  font-light text-center max-w-[226px] mx-auto">
                      {desc}
                    </p>
                    <p className=" font-medium text-[42px] text-center">
                      {priceVal <= 4
                        ? `$${priceTill4}`
                        : priceVal <= 19
                        ? `$${priceTill19}`
                        : priceVal <= 99
                        ? `$${priceatill99}`
                        : "Contact Sale"}
                    </p>
                    <p className="text-center text-xs font-normal max-w-[106px] mx-auto">
                      {shortDesc}
                    </p>
                    <div className="px-4">
                      <a
                        href="#"
                        className="block w-full px-6 py-3 mt-8 font-medium text-center text-white bg-black border border-transparent rounded-md"
                      >
                        {btn}
                      </a>
                    </div>
                    <h6 className="font-semibold text-base mt-[22px]">
                      {feature}
                    </h6>
                    <ul role="list" className="mt-2 space-y-2">
                      {answer.map((ans) => {
                        return (
                          <li className="flex">
                            <TiTick className="text-purple-primary" />
                            <span className="ml-3  text-[15px]">{ans}</span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Plans;
const priceInfo = [
  {
    id: 1,
    name: "Basic",
    title: "Mobile",
    desc: "Stay connected with our mobile and desktop apps; great for remote and on the go.",
    priceTill4: 19.99,
    priceTill19: 17.99,
    priceatill99: 14.99,

    shortDesc: "per month/per line plus taxes & fees",
    btn: "Buy now",
    link: "",
    feature: "features",
    answer: [
      `Mobile and desktop apps`,
      `Unlimited Calls and SMS2`,
      `Vonage App Center3`,
      `Unlimited Team Messaging`,
    ],
  },
  {
    id: 2,
    name: "MOST POPULAR",
    title: "Premium",
    desc: "Collaborate and communicate with our desktop and mobile apps plus your IP deskphones.",
    priceTill4: 29.99,
    priceTill19: 27.99,
    priceatill99: 24.99,
    shortDesc: "per month/per line plus taxes & fees",
    btn: "Buy now",
    link: "",
    feature: "All Mobile Plan features AND:",
    answer: [
      `Unlimited meetings for up to 100`,
      `Multi-level auto attendant`,
      `CRM Integration4`,
      `IP desk phone capability`,
    ],
  },
  {
    id: 3,
    name: "ALL THE BELLS & WHISTLES",
    title: "Advanced",
    desc: "Level up and gain productivity with included premium calling features.",
    priceTill4: 39.99,
    priceTill19: 37.99,
    priceatill99: 34.99,
    shortDesc: "per month/per line plus taxes & fees",
    btn: "Buy now",
    link: "",
    feature: "All Premium Plan features AND:",
    answer: [
      `Call Recording: on-demand (15 hours)`,
      `Call Group`,
      `Visual Voicemail`,
    ],
  },
];
